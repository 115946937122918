<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div class="confirm">
        <main-steps :active="4"></main-steps>
        <div class="content">
            <!-- 主体信息开始 -->
            <title-c textc="主体信息"></title-c>
            <el-row
                v-if="subject.type === 'true'"
                type="flex"
                justify="space-between"
                class="row-bg"
            >
                <el-col :span="10">
                    <span>投诉人帐号主体类型:</span>
                    <span>组织</span>
                </el-col>
                <el-col
                    :span="10"
                    :title="subject.cate"
                >
                    <span>名称:</span>
                    <span>{{ subject.cate }}</span>
                </el-col>
            </el-row>
            <el-row
                v-if="subject.type === 'false'"
                type="flex"
                justify="space-between"
                class="row-bg"
            >
                <el-col :span="10">
                    <span>投诉人帐号主体类型:</span>
                    <span>个人</span>
                </el-col>
                <el-col :span="10">
                    <span>姓名:</span>
                    <span>{{ subject.cate }}</span>
                </el-col>
            </el-row>
            <el-row
                v-if="subject.type === 'true'"
                type="flex"
                class="row-bg"
                justify="space-between"
            >
                <el-col
                    :span="10"
                    :title="subject.number"
                >
                    <span>证件编号:</span>
                    <span>{{ subject.number }}</span>
                </el-col>
                <el-col :span="10">
                    <p class="img">
                        <span>证件原件:</span>
                        <ul>
                            <li
                                v-for="item in subject.imageUrl"
                                :key="item"
                            >
                                <viewer :images="subject.imageUrl">
                                    <img
                                        :src="item"
                                        alt=""
                                    >
                                </viewer>
                            </li>
                        </ul>
                    </p>
                </el-col>
            </el-row>
            <el-row
                v-if="subject.type === 'false'"
                type="flex"
                class="row-bg"
            >
                <el-col>
                    <p class="img">
                        <span>证件原件:</span>
                        <ul>
                            <li v-for="item in subject.imageUrl" :key="item">
                                <viewer :images="subject.imageUrl">
                                    <img :src="item" alt="">
                                </viewer>
                            </li>
                        </ul>
                    </p>
                </el-col>
            </el-row>
            <el-row
                v-if="subject.type === 'true'"
                type="flex"
                class="row-bg"
                justify="space-between"
            >
                <el-col :span="10">
                    <span>证件有效期:</span>
                    <span v-if="subject && subject.time">
                        {{ subject.time[0] | rTime }} - {{ subject.time[1] | rTime }}
                    </span>
                    <span v-else>
                        --
                    </span>
                </el-col>
                <el-col :span="10" :title="subject.name">
                    <span>法人代表姓名:</span>
                    <span>{{ subject.name }}</span>
                </el-col>
            </el-row>
            <el-row
                type="flex"
                justify="space-between"
                class="row-bg"
            >
                <el-col :span="10">
                    <span>邮箱:</span>
                    <span>{{ subject.email }}</span>
                </el-col>
                <el-col :span="10">
                    <span>手机号:</span>
                    <span>{{ subject.tel }}</span>
                </el-col>
            </el-row>
            <el-row
                type="flex"
                v-if="subject.type === 'false'"
                justify="space-between"
                class="row-bg"
            >
                <el-col>
                    <span>{{ subject.type === 'true' ? '法人代表身份证号' : '身份证号码' }}:</span>
                    <span>{{ subject.ID }}</span>
                </el-col>
            </el-row>
            <!-- 主体信息结束 -->
            <!-- 权属信息开始 -->
            <title-c textc="权属信息"></title-c>
            <el-row
                type="flex"
                justify="space-between"
                class="row-bg"
            >
                <el-col :span="10">
                    <span>权属身份类型:</span>
                    <span>{{ ownership.pType === 1 ? "权利人" : "代理人" }}</span>
                </el-col>
                <el-col :span="10" :title="ownership.workName">
                    <span>作品名称:</span>
                    <span>{{ ownership.workName }}</span>
                </el-col>
            </el-row>
            <el-row
                type="flex"
                justify="space-between"
                class="row-bg"
            >
                <el-col :span="10">
                    <span>作品类型:</span>
                    <span>{{ ownership.workType }}</span>
                </el-col>
                <el-col :span="10">
                    <span>授权有效期:</span>
                    <span v-if="ownership && ownership.time">
                        {{ ownership.time[0] | rTime }} - {{ ownership.time[1] | rTime }}
                    </span>
                    <span v-else>
                        --
                    </span>
                </el-col>
            </el-row>
            <el-row
                type="flex"
                justify="space-between"
                class="row-bg"
                v-if="ownership.workType === '文字'"
            >
                <el-col :span="10" :title="ownership.pseudonym">
                    <span>作者名称/笔名:</span>
                    <span>{{ ownership.pseudonym }}</span>
                </el-col>
            </el-row>
            <el-row type="flex" class="row-bg">
                <el-col>
                    <p class="image">
                        <span>权属证明:</span>
                        <ul>
                            <li v-for="(value, key) in ownership.arr" :key="key">
                                <p v-if="key">{{ key }}</p>
                                <viewer :images="value">
                                    <img
                                        v-for="items in value"
                                        :key="items"
                                        :src="items"
                                        alt=""
                                    >
                                </viewer>
                            </li>
                        </ul>
                    </p>
                </el-col>
            </el-row>
            <title-c v-if="ownership.pType !== 1" textc="授权信息"></title-c>
            <el-row
                v-if="ownership.pType !== 1"
                type="flex"
                justify="space-between"
                class="row-bg"
            >
                <el-col :span="10">
                    <span>权利人名称:</span>
                    <span>{{ ownership.authorizeName }}</span>
                </el-col>
                <el-col :span="10">
                    <span>权利人电话:</span>
                    <span>{{ ownership.authorizeTel }}</span>
                </el-col>
            </el-row>
            <el-row
                v-if="ownership.pType !== 1"
                type="flex"
                class="row-bg"
            >
                <el-col>
                    <span>权利人邮箱:</span>
                    <span>{{ ownership.authorizeEmail }}</span>
                </el-col>
            </el-row>
            <el-row
                v-if="ownership.pType !== 1"
                type="flex"
                class="row-bg"
            >
                <p class="img">
                    <span>权利人主体身份证明:</span>
                    <ul>
                        <li v-for="item in ownership.fileList1" :key="item.url">
                            <viewer :images="ownership.fileList">
                                <img :src="item.url" alt="">
                            </viewer>
                        </li>
                    </ul>
                </p>
            </el-row>
            <el-row
                v-if="ownership.pType !== 1"
                type="flex"
                class="row-bg"
            >
                <p class="img">
                    <span>授权证明:</span>
                    <ul>
                        <li v-for="item in ownership.fileList2" :key="item.url">
                            <viewer :images="ownership.fileList2">
                                <img :src="item.url" alt="">
                            </viewer>
                        </li>
                    </ul>
                </p>
            </el-row>
            <!-- 权属信息结束 -->
            <!-- 投诉内容开始 -->
            <title-c textc="投诉内容"></title-c>
            <div class="c-item border">
                <p class="combination">
                    <span>投诉产品:</span>
                    <span>
                        <i :style="{backgroundImage: 'url(' + complaint.icon + ')'}"></i>
                        {{ complaint.value }}
                    </span>
                </p>
                <p class="combination long long-test">
                    <span>投诉问题描述:</span>
                    <span>
                        {{ complaint.textarea }}
                    </span>
                </p>
                <p class="combination long">
                    <span>原版标题:</span>
                    <span>
                        {{ complaint.actualName }}
                    </span>
                </p>
                <br />
                <p class="combination long">
                    <span>原版链接:</span>
                    <span>
                        {{ complaint.actualUrl }}
                    </span>
                </p>
                <p class="combination" v-if="complaint.tortVal">
                    <span>侵权类型:</span>
                    <span>
                        {{ complaint.tortVal }}
                    </span>
                </p>
                <div class="table">
                    <p-table
                        :show="true"
                        :tabledata="complaint.linkList"
                        :tablename="tableName"
                        :shows="true"
                    ></p-table>
                </div>
            </div>
            <!-- 投诉内容结束 -->
            <div class="ensure">
                <el-checkbox v-model="checked"></el-checkbox>
                <span @click="select">
                    <span>我保证并承诺，本投诉内容准确无误，包括所作陈述和提供的材料皆真实有效并合法。</span>
                    <span>本人保证承担和赔偿百度因根据本通知对被投诉内容或被投诉帐号采取处理措施而遭受的全部损失，包括但不限于</span>
                    <span>百度因向投诉方或用户赔偿而产生的经济损失以及给百度名誉、商誉造成的损害等。</span>
                    <span>我了解，为保护各方合法权益，百度将在法律规定的范围内将我提交的材料向被投诉方披露。</span>
                </span>
            </div>
            <div class="btnList">
                <next-tick
                    :btn1="0"
                    :btn2="1"
                    :btn3="0"
                    :btn4="1"
                    @subm="subm"
                ></next-tick>
            </div>
        </div>
    </div>
</template>

<script>
import TitleC from '@/components/publicComponents/TitleC.vue';
import MainSteps from '@/views/MainSteps.vue';
import PTable from '@/components/publicComponents/PTable.vue';
import NextTick from '@/components/NextTick.vue';
import { upload } from '@/api/getData.js';
import { getKey, getKey2 } from '@/utils/public.js';
import product from '@/assets/data/product.js';
import Bg2 from '@/assets/img/point2.png';
export default {
    components: {
        TitleC,
        MainSteps,
        PTable,
        NextTick,
    },
    data() {
        return {
            checked: false,
            $viewer: {},
            isupload: true,
            complaint: {},
            subject: {},
            ownership: {},
            tabledata: [],
            allData: {},
            Bg2,
            tableName: [
                {
                    name: '序号',
                    key: 'id',
                },
                {
                    name: '链接名称',
                    key: 'url_name',
                },
                {
                    name: '链接地址',
                    key: 'url',
                },
            ],
        };
    },
    methods: {
        select() {
            this.checked = !this.checked;
        },
        subm() {
            if (!this.checked) {
                this.$message.error('请确认真实性保证');
            } else {
                if (!this.isupload) {
                    return;
                }
                this.isupload = false;
                upload(this.allData).then((res) => {
                    const { code, data, message } = res || {};
                    if (code === 200) {
                        this.$message({
                            message: '提交成功',
                            type: 'success',
                        });
                        sessionStorage.clear();
                        setTimeout(() => {
                            this.$router.push({
                                path: '/complete',
                            });
                        }, 200);
                    } else {
                        // 补充提交的错误信息，方便后期排查
                        this.$message.error(message);
                        this.isupload = true;
                    }
                });
            }
        },
        gType(type) {
            let obj = this.$store.getters.getOptionsObj; // 获取作品类型列表
            for (var key in obj) {
                if (type + '' === key) {
                    return obj[key];
                }
            }
        },
        gCype(type) {
            let obj = {};
            product.forEach((item) => {
                obj[item.value] = item.label;
            });
            for (var key in obj) {
                if (type === key) {
                    return obj[key];
                }
            }
        },
    },
    created() {
        let subject = JSON.parse(sessionStorage.getItem('subject'));
        if (subject) {
            this.subject = subject;
        }
        let complaint = sessionStorage.getItem('complaint');
        if (complaint) {
            this.complaint = JSON.parse(complaint);
        }
        let index = 1;
        this.complaint.linkList.forEach((item) => {
            item.id = index++;
        });
    },
    mounted() {
        let ownership = sessionStorage.getItem('ownership');
        let fileList1 = [];
        let fileList2 = [];
        if (ownership) {
            this.ownership = JSON.parse(ownership);
            if (typeof this.ownership.arr === 'string') {
                this.ownership.arr = JSON.parse(this.ownership.arr);
            }
            if (this.ownership.fileList1) {
                this.ownership.fileList1.forEach((item) => {
                    fileList1.push(item.url);
                });
                this.ownership.fileList2.forEach((item) => {
                    fileList2.push(item.url);
                });
            }
        }
        let prove = {};
        if (!this.ownership.updata) {
            this.ownership.arr.forEach((item) => {
                let arr = [];
                item.file.forEach((items) => {
                    arr.push(items.response?.data[0]);
                });
                prove[getKey(item.ident.label)] = arr;
            });
            let obj = {};
            for (let key in prove) {
                obj[getKey2(key)] = prove[key];
            }
            this.ownership.arr = obj;
        } else {
            prove = this.ownership.arr;
            let obj = {};
            for (let key in this.ownership.arr) {
                obj[getKey2(key)] = this.ownership.arr[key];
            }
            this.ownership.arr = obj;
        }
        var own = {
            updata: this.ownership.updata,
            cp_id: this.ownership.cp_id,
        };
        let linkList = [];
        this.complaint.linkList.forEach((item) => {
            linkList.push({
                link_name: item.url_name,
                url_address: item.url,
            });
        });
        let workType = this.ownership.workType;
        this.ownership.workType = this.gType(this.ownership.workType);
        let value = this.complaint.value;
        let tortType = [
            {
                value: 1,
                label: '影视版权',
            },
            {
                value: 2,
                label: '综艺版权',
            },
            {
                value: 3,
                label: '动漫动画版权',
            },
            {
                value: 4,
                label: '音乐版权',
            },
            {
                value: 5,
                label: '游戏版权',
            },
            {
                value: 6,
                label: '体育赛事版权',
            },
            {
                value: 7,
                label: '新闻媒体版权',
            },
            {
                value: 8,
                label: '自媒体版权',
            },
        ];
        this.complaint.value = this.gCype(this.complaint.value + '');
        var infringe_type;
        tortType.forEach((item) => {
            if (item.value === this.complaint.tortVal) {
                infringe_type = this.complaint.tortVal;
                this.complaint.tortVal = item.label;
            }
        });
        if (!this.subject.time) {
            this.subject.time = ['', ''];
        }
        if (!this.ownership.time) {
            this.ownership.time = ['', ''];
        }
        let extras = sessionStorage.getItem('extras') - 0 || null;
        if (extras !== 1) {
            extras = 2;
        }
        this.allData = {
            create_complaint_form: {
                complaint_type: value, // 投诉产品
                description: this.complaint.textarea, // 投诉描述
                url_list: linkList, // 链接列表
                infringe_type,
                actual_url: this.complaint.actualUrl,
                actual_name: this.complaint.actualName
            },
            create_owner_ship_form: {
                authorization_url: fileList2, // 授权证明
                contact_name: this.ownership.authorizeName, // 授权人姓名
                cp_id: own.cp_id,
                email: this.ownership.authorizeEmail, // 授权人邮箱
                mobile_phone: this.ownership.authorizeTel, // 授权人电话
                owner_type: this.$store.state.pType, // 权属分类
                owner_url: fileList1, // 权利人身份证明
                update: own.updata, // true代表更新， false代表新增(false下，cp_id为null)
                works_category: workType, // 作品类型
                pseudonym: this.ownership.pseudonym, // 作者名称/笔名
                works_certificate_url: JSON.stringify(prove), // 权属证明
                works_end_date: this.ownership.time[1], // 作品权属有效期截至时间
                works_name: this.ownership.workName,
                works_start_date: this.ownership?.time[0], // 作品权属有效期起始日期
            },
            create_user_form: {
                card_end_date: this.subject.time[1], // 证件结束日期
                card_start_date: this.subject?.time[0], // 证件开始日期
                code_type: 1, // 证件类型  1：工商营业执照，2：组织机构代码
                company_code: this.subject.number, // 营业执照编号or组织机构代码
                complaint_account: this.subject.cate, // 投诉pass帐号
                contact_name: this.subject.contacts, // 联系人姓名
                email: this.subject.email, // 联系人邮箱
                id_card: this.subject.ID, // 个人身份证件号码or法人身份证号码
                legal_person: this.subject.name,
                license_url: this.subject.type === 'true' ? this.subject?.imageUrl[0] : '', // 公司证件照
                pass_display_name: '', // 用户昵称
                mobile_phone: this.subject.tel, // 联系人手机号码
                pics_url: this.subject.type === 'false' ? this.subject.imageUrl : [], // 身份证证件图片存储地址：头像、国徽、手持（保持顺序）
                reputation_type: extras, // 百度信誉验证  1通过 2不通过
                update: this.subject.update, // 修改（false） or 新增（true）
                user_name: this.subject.cate, // 实名名称，个人真实姓名或企业机构名称
                user_type: this.subject.type === 'true' ? 2 : 1, // 用户性质(1个人, 2组织)
            },
        };
    },
};
</script>

<style scoped lang='less'>
::v-deep .is-checked .el-checkbox__inner {
    background-color: #2d63e0;
    border-color: #2d63e0;
}
::v-deep .el-table tbody tr:hover > td {
    background-color: #e6edff !important;
}
/deep/ .el-table th.is-leaf {
    border: none;
}
/deep/ .el-table td {
    border: none;
}
/deep/ .el-table {
    .ellipsis {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
    }
}
.border {
    border-bottom: 1px dotted #e6e6eb;
    padding-bottom: 20px;
}
.row-bg {
    min-height: 45px;
    line-height: 40px;
    font-size: 14px;
    color: #383d47;
    span:nth-child(2) {
        margin-left: 10px;
    }
    .el-col {
        overflow: hidden;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .img {
        display: flex;
        span {
            display: inline-block;
            font-size: 14px;
            color: #383d47;
            line-height: 1;
        }
        ul {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            li {
                margin: 0 10px;
                img {
                    width: 80px;
                    height: 60px;
                    border-radius: 4px;
                }
            }
        }
    }
    .image {
        display: flex;
        ul {
            margin-left: 10px;
            li {
                margin-bottom: 5px;
                div {
                    height: 60px;
                }
                img {
                    width: 80px;
                    height: 60px;
                    border-radius: 4px;
                    margin-right: 20px;
                }
            }
        }
    }
}
.confirm {
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    text-align: left;
    .content {
        width: 700px;
        min-height: 100vh;
        margin: 0 0 0 20%;
        .ensure {
            display: flex;
            width: 520px;
            margin-top: 20px;
            font-size: 14px;
            color: #383d47;
            > span {
                margin-left: 10px;
                font-size: 0;
                span {
                    font-size: 14px;
                }
            }
        }
        .c-item {
            width: 700px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .table {
                width: 700px;
            }
            .combination {
                min-width: 233px;
                margin-bottom: 24px;
                i {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background-size: 100% 100%;
                    vertical-align: top;
                    border: 1px solid rgba(30, 32, 36, 0.1);
                    border-radius: 3px;
                    background-repeat: no-repeat;
                }
                :nth-child(2) {
                    margin-left: 10px;
                }
            }
            .numcom {
                margin-left: 63px;
                margin-top: -1px;
            }
            .long {
                display: flex;
                width: 478px;
                span:nth-child(1) {
                    display: inline-block;
                    width: 80px;
                }
                span:nth-child(2) {
                    flex: 1;
                    word-break: break-word;
                    margin-left: -10px;
                }
            }
            .long-test {
                span:nth-child(1) {
                    width: 110px;
                }
            }
            > p {
                font-size: 14px;
                color: #383d47;
            }
            .img {
                display: flex;
                span {
                    display: inline-block;
                    width: 60px;
                    font-size: 14px;
                    color: #383d47;
                }
                ul {
                    display: flex;
                    flex: 1;
                    li {
                        margin: 0 10px;
                        img {
                            width: 80px;
                            height: 60px;
                            border-radius: 4px;
                        }
                    }
                }
            }
            .image {
                display: flex;
                ul {
                    margin-left: 10px;
                    li {
                        margin-bottom: 10px;
                        img {
                            width: 80px;
                            height: 60px;
                            border-radius: 4px;
                            margin-right: 20px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>
